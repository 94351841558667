/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import { withTranslation } from 'react-i18next'

import * as API from '../../../../redux/api/user'

import PageTopPart from '../../../PageTopPart/PageTopPart.jsx'

import withStyles from '@material-ui/core/styles/withStyles'

import GridContainer from 'components/Grid/GridContainer.jsx'

// style
import styles from '../profile.module.scss'
import * as S from './styles'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx'

// UTILS
import compose from 'utils/compose'
import { FormatCNPJ } from '../../../../utils/utils'
import BankAndPixForm_Bank from './BankAndPixForm_Bank.jsx'
import BankAndPixForm_Pix from './BankAndPixForm_Pix.jsx'
import Loader from 'components/Loader/Loader.jsx'

const BankAndPixForm = ({
    t,
    classes,
    values_Account,
    errors,
    touched,
    // ===== form de conta bancaria e pix ========
    handleSave_Account,
    // handleChange_Account,
    handleEdit_Account,
    handleCancel_Account,
    disabledForm_Account,
    allBanksFromAPI,
    // selectListBank,
    allPixKeysType,
    // ===================================
    setFieldTouched,
    setFieldValue,
    handleChange,

    setSelectedPixKeyType,
    setSelectedPixKey,
    setSelectedBankNumber,
    setSelectedBankID,
    setSelectedBankBranch,
    setNumberDigit,
}) => {
    const updateLocaleSettings = () => {
        if (localStorage.getItem('i18nextLng') != null) {
            moment.locale(localStorage.getItem('i18nextLng'))
        } else {
            moment.locale('pt-BR')
        }
    }

    const [loading, setLoading] = useState(false)

    //states do pix
    const [CPFFromAPI, setCPFFromAPI] = useState(null)
    const [cellFromAPI, setCellFromAPI] = useState(null)
    const [emailFromAPI, setEmailFromAPI] = useState(null)

    const [empty, setEmpty] = useState(false)
    const [selectedOption_CPF, setSelectedOption_CPF] = useState(false)
    const [selectedOption_Celular, setSelectedOption_Celular] = useState(false)
    const [selectedOption_Email, setSelectedOption_Email] = useState(false)
    const [selectedOption_Aleatoria, setSelectedOption_Aleatoria] =
        useState(false)
    const [selectedKey, setSelectedKey] = useState(null) //pro input value
    const [selectedType, setSelectedType] = useState(null) //pro input value
    const [selectedTypeIndex, setSelectedTypeIndex] = useState(null) //pro input value

    // states do banco
    const [bankKey, setBankKey] = useState('')
    const [bankName, setBankName] = useState('')
    const [agency, setAgency] = useState('')
    const [account, setAccount] = useState('')
    const [_numberDigit, set_NumberDigit] = useState('')

    const mask_cpf = (value) => {
        if (value?.length > 11) return FormatCNPJ(value)
        return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }
    const mask_cell = (value) => {
        return value?.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
    }

    const mask_aleatory = (value) => {
        return value?.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1.$2.$3.$4')
    }

    const noneMask = (value) => {
        return value
    }

    const clearMask = (value) => {
        if (selectedOption_Email === true) return value

        return value ? value?.replace(/[^\d]/g, '') : ''
    }

    const maskedInitialValue = (_value, _pixType) => {
        switch (_pixType?.toLowerCase()) {
            case 'cpf':
                return mask_cpf(_value)
            case 'celular':
                return mask_cell(_value)
            case 'chave aleatória':
                return mask_aleatory(_value)
            default:
                return _value
        }
    }

    const maskedPixType = (_value) => {
        switch (_value) {
            case 'AleatoryKey':
                return null //1
            case 'CPF':
                return CPFFromAPI?.length <= 11 ? 'CPF' : 'CNPJ'
            case 'Cellphone':
                return 'Celular' //3
            case 'Email':
                return 'E-mail' //4
            default:
                return _value
        }
    }

    const SelectKeyValue = (_value, _maskMethod = noneMask) => {
        setSelectedPixKey(
            selectedTypeIndex !== '4' ? clearMask(_value) : _value,
        ) // -> pro componente pai, vai pra requisicao da API quando der submit

        setSelectedKey(`${_maskMethod(_value)}`) //pro input value
    }

    const updateBasedOnAPI = () => {
        setLoading(true)
        API.GetCurrentConsumer('')
            .then((res) => {
                //pegando valores da API ---------------------------------
                const _bankKey = res?.consumerAccount?.bank?.key || '0'
                const _bankName = res?.consumerAccount?.bank?.value
                const _agency = res?.consumerAccount?.branch
                const _account = res?.consumerAccount?.number
                const _numberDigits = res?.consumerAccount?.numberDigit
                //usando os valores pro valor visual do input e no 'cancelar' ------------------------------
                setBankKey(_bankKey)
                setBankName(_bankName)
                setAgency(_agency)
                setAccount(_account)
                set_NumberDigit(_numberDigits)
                //usando os valores pra enviar pra requisicao no 'salvar' ------------------------------
                setSelectedBankID(_bankKey)
                setSelectedBankNumber(_account)
                setSelectedBankBranch(_agency)
                setNumberDigit(_numberDigits)
                //PIX =========================================================================================

                //tipo de chave
                const _pixType = maskedPixType(
                    res?.consumerAccount?.keyType?.key,
                )
                const _pixTypeIndex = res?.consumerAccount?.keyType?.key

                //chave em si
                const _pixKey = maskedInitialValue(
                    res?.consumerAccount?.key,
                    _pixType,
                )

                //valores da API pra atualizar dps o form
                const _pixValue = res?.consumerAccount?.key
                setCPFFromAPI(_pixType === '1' ? _pixValue : res?.cpf)
                setCellFromAPI(_pixType === '2' ? _pixValue : res?.phoneNumber2)
                setEmailFromAPI(_pixType === '3' ? _pixValue : res?.email)

                //pra atualizar o valor visual do input
                setSelectedKey(_pixKey)
                setSelectedType(_pixType)
                setSelectedTypeIndex(_pixTypeIndex)
                //================================

                //pra enviar pra requisicao
                setSelectedPixKey(_pixKey)
                setSelectedPixKeyType(_pixType)
                //===============================

                setSelectedOption_CPF(_pixType === '1' ? true : false)
                setSelectedOption_Email(_pixType === '3' ? true : false)
                setSelectedOption_Celular(_pixType === '2' ? true : false)
            })
            .catch((err) => {
                console.log(
                    'Não foi possível resgatar os dados de transferência deste usuário.' +
                        err,
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const filteredPixKeysType = allPixKeysType?.map((keyType) => ({
        ...keyType,
        disabled: keyType?.key?.toLowerCase() === '4',
    }))

    useState(() => {
        updateLocaleSettings()
        updateBasedOnAPI()
    }, [])

    return (
        <S.ContainerBankAndPixForm>
            {loading && <Loader />}
            <PageTopPart
                documentTitle={t('TITLE_PROFILE')}
                formTitle={t('TITLE_BANK_ACCOUNT')}
                handleEdit={handleEdit_Account}
                handleSave={Boolean(errors.account) ? null : handleSave_Account}
                handleCancel={() => {
                    handleCancel_Account()
                    updateBasedOnAPI()
                }}
                editName={t('BUTTON_EDIT')}
                cancelName={t('BUTTON_CANCEL')}
                saveName={t('BUTTON_SAVE')}
                disabledForm={disabledForm_Account}
                marginTop={0}
            />

            <GridContainer>
                <BankAndPixForm_Pix
                    setSelectedPixKeyType={setSelectedPixKeyType}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    selectList={filteredPixKeysType}
                    errors={errors}
                    classes={classes}
                    styles={styles}
                    values_Account={values_Account}
                    touched={touched}
                    disabledForm_Account={disabledForm_Account}
                    mask_cpf={mask_cpf}
                    mask_cell={mask_cell}
                    mask_aleatory={mask_aleatory}
                    clearMask={clearMask}
                    selectedOption_CPF={selectedOption_CPF}
                    selectedOption_Email={selectedOption_Email}
                    selectedOption_Celular={selectedOption_Celular}
                    selectedOption_Aleatoria={selectedOption_Aleatoria}
                    empty={empty}
                    setEmpty={setEmpty}
                    setSelectedOption_CPF={setSelectedOption_CPF}
                    setSelectedOption_Celular={setSelectedOption_Celular}
                    setSelectedOption_Email={setSelectedOption_Email}
                    selectedKey={selectedKey}
                    CPFFromAPI={CPFFromAPI}
                    emailFromAPI={emailFromAPI}
                    cellFromAPI={cellFromAPI}
                    setSelectedType={setSelectedType}
                    selectedTypeIndex={selectedTypeIndex}
                    setSelectedTypeIndex={setSelectedTypeIndex}
                    SelectKeyValue={SelectKeyValue}
                    maskedPixType={maskedPixType}
                    setSelectedOption_Aleatoria={setSelectedOption_Aleatoria}
                />

                <BankAndPixForm_Bank
                    setSelectedBankNumber={setSelectedBankNumber}
                    setSelectedBankID={setSelectedBankID}
                    setSelectedBankBranch={setSelectedBankBranch}
                    setNumberDigit={setNumberDigit}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    selectList={allBanksFromAPI}
                    errors={errors}
                    classes={classes}
                    styles={styles}
                    touched={touched}
                    disabledForm_Account={disabledForm_Account}
                    setBankKey={setBankKey}
                    setBankName={setBankName}
                    setAgency={setAgency}
                    setAccount={setAccount}
                    set_NumberDigit={set_NumberDigit}
                    bankKey={bankKey}
                    agency={agency}
                    account={account}
                    numberDigit={_numberDigit}
                />
            </GridContainer>
        </S.ContainerBankAndPixForm>
    )
}

export default compose(
    withTranslation(),
    withStyles(extendedFormsStyle),
    withStyles(userProfileStyles),
)(BankAndPixForm)
