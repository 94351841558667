import React from 'react'
import { ModalContainer, PaperContainer } from './styles'
import { Slide, Grid } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import { useTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} unmountOnExit />
})

export default function ModalMobile({
    children,
    open,
    closeByClick,
    maxWidth,
    className,
    padding,
    shadow,
    headTitle,
    onExited,
    changeModal,
    sendSubmit,
    themeColor,
}) {
    const { t } = useTranslation()
    return (
        <ModalContainer
            open={open}
            TransitionComponent={Transition}
            keepMounted
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxwidth={maxWidth}
            shadow={shadow}
            onExited={onExited}
        >
            <PaperContainer
                padding={padding}
                style={{
                    paddingRight: '20px',
                }}
                themeColor={themeColor}
            >
                {changeModal ? (
                    <Grid
                        container
                        alignItems="center"
                        className="mb-2 header-2"
                        spacing={2}
                    >
                        <Grid item xs={3} sm={3} container alignItems="center">
                            <b>{t('Confirm_Mobile')}</b>
                        </Grid>
                        <Grid item xs={3} sm={5} container alignItems="center">
                            <div className="line"></div>
                        </Grid>
                        <Grid item xs={3} sm={2} onClick={sendSubmit}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                size="sm"
                            >
                                {t('BUTTON_CONFIRM')}
                            </Button>
                        </Grid>
                        <Grid item xs={3} sm={2} onClick={closeByClick}>
                            <Button
                                type="button"
                                variant="contained"
                                // color="primary"
                                size="sm"
                                color="greenBorderButtonColor"
                                // disabled={loggedIn || loggingIn}
                            >
                                {t('BUTTON_CANCEL')}
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        alignItems="center"
                        className="mb-2 header-1"
                        spacing={3}
                        style={{
                            paddingRight: '25px',
                        }}
                    >
                        <Grid
                            item
                            xs={2}
                            sm={1}
                            container
                            alignItems="center"
                            className="close"
                        >
                            <b>{t('Attention')}</b>
                        </Grid>
                        <Grid item xs={8} sm={10} container alignItems="center">
                            <div className="line"></div>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sm={1}
                            className="close"
                            onClick={closeByClick}
                        >
                            <span>{t('BUTTON_CLOSE')}</span>
                        </Grid>
                    </Grid>
                )}
                {children}
            </PaperContainer>
        </ModalContainer>
    )
}
